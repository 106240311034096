<template>
  <b-card title="Financial ">
    <b-card-text>Financial.</b-card-text>
  </b-card>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
  },
};
</script>

<style></style>
